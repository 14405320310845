.logo {
  font-family: 'Satisfy', cursive;
  text-align: center;
  position: relative;
  margin-right: 1rem;
}

.logo:after {
  content: '';
  position: absolute;
  bottom: 20px;
  left: 0;
  height: 3px;
  width: 100%;
  border: solid 5px #ffbf00;
  border-color: #ffbf00 transparent transparent transparent;
  border-radius: 50%;
  font-size: 200%;
}
