.app {
  text-align: center;
  background: #ffffff;
}

.app-header {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  font-size: calc(10px + 2vmin);
  color: #8a4f7d;
}

.app-footer {
  min-height: 5vh;
  box-shadow: rgba(136, 120, 128, 0.06) 0px 2px 4px 0px inset;
}

.app-footer-copyright {
  padding: 5px;
}

.app-description,
.app-text {
  font-family: 'Quicksand';
}

.app-description {
  color: #7f9794;
}

.app-text {
  color: #2274a5;
}

.app-text-second-line {
  color: #137e4d;
}

.app-description {
  max-width: 60%;
}

.app-text {
  max-width: 80%;
}

.app-form {
  font-family: 'Quicksand';
  font-size: large;
  background-color: #ffffff;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
}

.app-form-text {
  max-width: 80%;
  color: #2e2e3a;
}

.app-form address {
  display: inline;
}

.app-link {
  color: #9d2b1c;
  font-weight: bold;
}
